<template>
    <div
        ref="modalBackground"
        class="flex absolute top-0 left-0 w-full h-full justify-center items-center bg-black bg-opacity-20 z-10"
    >
        <OffscreenMap
            ref="offscreenMap"
            :bearing="bearing"
            class="absolute top-0 left-0"
            :layers="[
                ...exportSettings.mapLayers.flatMap((layer) => layer.layers),
                surroundingAreaLayer,
            ]"
            :sources="[
                ...sources,
                surroundingAreaSource,
            ]"
        />
        <div
            ref="modalContent"
            class="modal-content flex absolute rounded-lg shadow-material-card"
            :style="{
                width: `${modalWidth}px`,
                height: `${modalHeight}px`,
            }"
        >
            <div
                ref="previewPanel"
                class="flex bg-white rounded-tl-lg rounded-bl-lg justify-center items-center"
                :style="{
                    width: 'calc(100% - 400px)',
                    padding: `${PREVIEW_PANEL_PADDING}px`,
                }"
            >
                <div class="flex flex-col gap-2 justify-center items-center">
                    <img
                        v-if="previewUri"
                        alt="Map Image Preview"
                        class="shadow-card"
                        :src="previewUri"
                        :style="{
                            maxWidth: `${previewPanelWidth - (2 * PREVIEW_PANEL_PADDING)}px`,
                            maxHeight: `${previewPanelHeight - (2 * PREVIEW_PANEL_PADDING)}px`,
                        }"
                    >
                    <div
                        v-if="previewUri"
                        class="flex flex-col w-full items-end"
                    >
                        <span class="font-supermolot-bold uppercase text-xs tracking-widest text-dhagray-200">
                            Dimensions
                        </span>
                        <span class="font-supermolot tracking-wider">
                            {{ `${canvas.width} x ${canvas.height}` }}
                        </span>
                    </div>
                </div>
            </div>
            <div
                class="preview-controls flex flex-col font-supermolot gap-6 pt-16 px-8
                bg-dhagray-50 rounded-tr-lg rounded-br-lg overflow-y-auto"
                style="width:400px"
            >
                <div class="font-supermolot-demibold text-2xl">
                    Download Map Image
                </div>
                <div class="flex flex-col">
                    <label
                        class="flex flex-col"
                        for="filename-input"
                    >
                        <input
                            id="filename-input"
                            v-model="exportSettings.filename"
                            class="border border-dhagray-200 rounded py-1 px-1 bg-dhagray-50
                            font-supermolot font-normal text-base"
                            minlength="1"
                            required
                            type="text"
                        >
                        <span class="font-supermolot-bold uppercase text-xs tracking-widest py-1 px-2 text-dhagray-200">
                            Filename
                        </span>
                    </label>
                </div>
                <ButtonGroup
                    label="File Type"
                    :options="extensionOptions"
                    :selected="exportSettings.mimeType"
                    @change="onChangeExtension"
                />
                <ButtonGroup
                    label="Focus"
                    :options="focusOptions"
                    :selected="exportSettings.focus"
                    @change="onChangeFocus"
                />
                <div
                    v-if="exportSettings.contextSections.length > 0"
                    class="flex flex-col"
                >
                    <span class="font-supermolot-demibold text-base">Context</span>
                    <div class="flex flex-col pl-4">
                        <SettingCheckbox
                            v-for="(section, i) in sortBy(exportSettings.contextSections, 'orderBy')"
                            :key="`context-section-${i}`"
                            v-model="section.enabled"
                            :label="section.label"
                            @update:model-value="render()"
                        />
                    </div>
                </div>
                <div
                    v-if="exportSettings.mapLayers.length > 0"
                    class="flex flex-col"
                >
                    <span class="font-supermolot-demibold text-base">Map Layers</span>
                    <div class="flex flex-col pl-4">
                        <SettingCheckbox
                            v-for="(mapLayer, i) in exportSettings.mapLayers"
                            :key="`setting-checkbox-${i}`"
                            v-model="mapLayer.enabled"
                            :label="mapLayer.label"
                            @update:model-value="(value: boolean) => onMapLayerToggled(mapLayer, value)"
                        />
                    </div>
                </div>
                <div
                    v-if="surroundingAreaFeature"
                    class="flex flex-col"
                >
                    <span class="font-supermolot-demibold text-base">Surrounding Area</span>
                    <div class="pl-4">
                        <SettingRadioGroup
                            v-model="exportSettings.surroundingAreaVisibility"
                            :options="Object.entries(SURROUNDING_AREA_DISPLAY_OPTIONS)
                                .map(([key, option]) => ({ id: key, label: capitalize(key), option }))"
                            @update:model-value="render"
                        />
                    </div>
                </div>
                <div class="flex flex-col gap-2 my-4">
                    <div class="flex flex-col gap-1">
                        <span class="text-legacy-red-500 h-6">{{ downloadError }}</span>
                        <DesButton @click="onClickDownload">
                            <div class="flex gap-2 justify-center items-center">
                                <span class="material-icons">download</span>
                                <span class="font-supermolot font-normal text-base">Download</span>
                            </div>
                        </DesButton>
                    </div>
                    <div
                        v-if="clipboardSupport"
                        class="flex flex-col gap-1"
                    >
                        <span class="text-legacy-red-500 h-6">{{ copyError }}</span>
                        <DesButton
                            variant="light"
                            @click="onClickCopyToClipboard"
                        >
                            <div class="flex gap-2 justify-center items-center">
                                <span class="material-icons">
                                    {{ copyButtonLabel === 'Copy to Clipboard' ? 'content_paste' : 'done' }}
                                </span>
                                <span class="font-supermolot font-normal text-base">{{ copyButtonLabel }}</span>
                            </div>
                        </DesButton>
                    </div>
                </div>
            </div>
            <LoadingOverlay v-if="isLoading || !previewUri" />
        </div>
    </div>
</template>

<script setup lang="ts">
import { onClickOutside, useElementSize } from '@vueuse/core';
import type { Feature, MultiPolygon, Polygon } from 'geojson';
import { capitalize, sortBy } from 'lodash';
import { LngLatBounds, type LngLatBoundsLike } from 'mapbox-gl';
import { computed, onMounted, reactive, ref } from 'vue';
import ButtonGroup from '@component-library/components/ButtonGroup/ButtonGroup.vue';
import DesButton from '@component-library/components/DesButton/DesButton.vue';
import LoadingOverlay from '@component-library/components/LoadingOverlay/LoadingOverlay.vue';
import {
    type MapLayer,
    type MapSource,
    SURROUNDING_AREA_DISPLAY_OPTIONS,
    useSurroundingAreaLayer,
} from '@component-library/components/Map';
import SettingCheckbox from '@component-library/components/SettingCheckbox/SettingCheckbox.vue';
import SettingRadioGroup from '@component-library/components/SettingRadioGroup/SettingRadioGroup.vue';
import OffscreenMap from './OffscreenMap.vue';
import { ToggleableMapLayer } from './layers/ToggleableMapLayer';
import type {
    ContextSectionSetting,
    FocusOption,
    MapExportSettings,
    MimeType,
    SavedMapExportSettings
} from './types';

const props = withDefaults(defineProps<{
    defaultFilename: string;
    contextSections: ContextSectionSetting[];
    viewportBounds?: LngLatBoundsLike;
    focusedBounds: LngLatBoundsLike;
    sources: MapSource[];
    dataLayers: Record<string, MapLayer[]>;
    surroundingAreaFeature: Feature<MultiPolygon | Polygon> | undefined;
    close(args: {
        settings: SavedMapExportSettings,
        imageExported: boolean,
        filename?: string,
    }): void;
    loadSettings?: SavedMapExportSettings | null;
    bearing?: number;
}>(), {
    // @ts-ignore
    viewportBounds: new LngLatBounds(),
    loadSettings: null,
    bearing: 0,
});

const modalBackground = ref<HTMLDivElement>();
const modalContent = ref<HTMLDivElement>();
const previewPanel = ref<HTMLDivElement>();

function handleClose(imageExported: boolean) {
    props.close(
        {
            settings: {
                mimeType: exportSettings.mimeType,
                focus: exportSettings.focus,
                contextSections: Object.fromEntries(
                    exportSettings.contextSections.map(({ label, enabled }) => [label, enabled])
                ),
                mapLayers: Object.fromEntries(exportSettings.mapLayers.map(({ label, enabled }) => [label, enabled])),
                surroundingAreaVisibility: exportSettings.surroundingAreaVisibility,
            },
            imageExported,
            filename: imageExported ? exportSettings.filename : undefined,
        }
    );
}

onClickOutside(modalContent, () => handleClose(false));

// Firefox doesn't support copying images to the clipboard and MDN docs are wrong
const clipboardSupport = computed(() => 'ClipboardItem' in window);

const { width: bgWidth, height: bgHeight } = useElementSize(modalBackground);

const isLoading = ref(false);
const previewUri = ref<string | null>(null);

const MODAL_PADDING = 196;
const modalWidth = computed(() => bgWidth.value - MODAL_PADDING);
const modalHeight = computed(() => bgHeight.value - MODAL_PADDING);

const PREVIEW_PANEL_PADDING = 32;
const { width: previewPanelWidth, height: previewPanelHeight } = useElementSize(previewPanel);

const downloadError = computed(() => ((exportSettings.filename.length === 0)
    ? 'Filename cannot be empty'
    : ''));
const copyButtonLabel = ref('Copy to Clipboard');
const copyError = ref('');

const exportSettings = reactive<MapExportSettings>({
    filename: props.defaultFilename,
    mimeType: props.loadSettings?.mimeType ?? 'image/png',
    focus: props.loadSettings?.focus ?? 'viewport',
    contextSections: props.contextSections.map((section) => ({
        ...section,
        enabled: props.loadSettings?.contextSections[section.label] ?? true,
    })),
    mapLayers: Object.entries(props.dataLayers).map(([label, layers]) => new ToggleableMapLayer(
        label,
        layers,
        props.loadSettings?.mapLayers[label] ?? true,
    )),
    surroundingAreaVisibility: props.loadSettings?.surroundingAreaVisibility ?? 'fade',
});

const {
    source: surroundingAreaSource,
    layer: surroundingAreaLayer,
} = useSurroundingAreaLayer(
    props.surroundingAreaFeature,
    () => exportSettings.surroundingAreaVisibility,
);

const extensionOptions: { value: MimeType; label: string; ext: string }[] = [{
    value: 'image/png',
    label: 'PNG',
    ext: 'png',
}, {
    value: 'image/jpeg',
    label: 'JPEG',
    ext: 'jpg'
}];

const focusOptions: { value: FocusOption; label: string }[] = [{
    value: 'viewport',
    label: 'Viewport'
}, {
    value: 'boundaries',
    label: 'Boundaries'
}];

const selectedExt = computed(() => extensionOptions
    .find((option) => option.value === exportSettings.mimeType)?.ext ?? 'png');

const offscreenMap = ref<InstanceType<typeof OffscreenMap> | null>(null);

onMounted(() => {
    setBoundaryFocus(exportSettings.focus);
    render();
});

const canvas = document.createElement('canvas');
const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;

async function render() {
    isLoading.value = true;

    if (!offscreenMap.value) {
        previewUri.value = null;
        return;
    }

    exportSettings.mapLayers.forEach((mapLayer) => {
        mapLayer.onLayerToggled(mapLayer.enabled);
    });

    const mapImage = await offscreenMap.value.generateImage();

    canvas.width = mapImage.width;
    canvas.height = mapImage.height;
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    ctx.drawImage(mapImage, 0, 0);

    for await (const setting of exportSettings.contextSections) {
        if (setting.enabled) {
            const resultCanvas = await setting.section.renderCanvas(canvas);
            canvas.width = resultCanvas.width;
            canvas.height = resultCanvas.height;
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            ctx.drawImage(resultCanvas, 0, 0);
        }
    }

    previewUri.value = canvas
        .toDataURL('image/png')
        .replace('image/png', 'image/octet-stream');

    isLoading.value = false;
}

function onClickDownload() {
    if (downloadError.value !== '' || !modalContent.value) {
        return;
    }

    const imageUri = canvas.toDataURL(exportSettings.mimeType);

    const downloadLink = document.createElement('a');
    downloadLink.href = imageUri;
    downloadLink.download = `${exportSettings.filename}.${selectedExt.value}`;
    modalContent.value.appendChild(downloadLink);
    downloadLink.click();
    modalContent.value.removeChild(downloadLink);

    handleClose(true);
}
function onClickCopyToClipboard() {
    canvas.toBlob(
        (imageBlob) => {
            if (!imageBlob) {
                displayCopyError('Unable to copy image to clipboard');
                return;
            }
            navigator.clipboard.write([new ClipboardItem({ [exportSettings.mimeType]: imageBlob })]).then(() => {
                displayCopySuccess();
            }).catch((err) => {
                displayCopyError('Unable to copy image to clipboard');
                console.error(err);
            });
        },
        exportSettings.mimeType
    );
}
function onChangeExtension(value: MimeType) {
    exportSettings.mimeType = value;
}
function onChangeFocus(value: FocusOption) {
    exportSettings.focus = value;
    setBoundaryFocus(exportSettings.focus);
    render();
}
function setBoundaryFocus(focus: string) {
    offscreenMap.value?.jumpToBounds(
        (focus === 'viewport') ? props.viewportBounds : props.focusedBounds,
    );
}
function displayCopySuccess() {
    copyButtonLabel.value = 'Copied!';
    setTimeout(() => {
        copyButtonLabel.value = 'Copy to Clipboard';
    }, 2000);
}
function displayCopyError(error: string) {
    copyError.value = error;
    setTimeout(() => {
        copyError.value = '';
    }, 3000);
}
function onMapLayerToggled(layer: ToggleableMapLayer, enabled: boolean) {
    layer.onLayerToggled(enabled);
    render();
}
</script>
