import { RouteRecordRaw } from 'vue-router';
import { useFeatureToggles } from '@/composables/featureToggles/useFeatureToggles';
import { redirectToValidRouteForUser } from '@/router/helpers/redirectToValidRouteForUser';
import { daAuthClient } from '@api-client';
import { beforeSettingsHelper } from './helpers/navigateToBeforeHelper';

const { featureIsEnabled } = useFeatureToggles();

const Sidebar = () => import('@/views/Sidebar');
const EmptyContainer = () => import('@/views/EmptyContainer.vue');

const TenantPickerPage = () => import('@/pages/TenantPicker');
const HqAppComponent = () => import('@/pages/Hq/HqApp.vue');

const HqHomeComponent = () => import('@hq/views/Home/Home.vue');
const DashboardComponent = () => import('@hq/views/Home/Dashboard/Dashboard.vue');
const MetricReportComponent = () => import('@hq/views/Home/MetricReport/MetricReport.vue');
const UnitHourUtilizationReportComponent = () => import('@hq/views/Home/UnitHourUtilization/UnitHourUtilizationReport.vue');
const CallConcurrencyReportComponent = () => import('@hq/views/Home/CallConcurrency/CallConcurrencyReport.vue');
const StationResponseReliabilityReportComponent = () => import('@hq/views/Home/StationResponseReliability/StationResponseReliabilityReport.vue');

const PrivacyPolicyComponent = () => import('@hq/views/PrivacyPolicy.vue');

const TenantSettingsComponent = () => import('@hq/views/Settings/Settings.vue');
const GeneralSettingsComponent = () => import('@hq/views/Settings/GeneralSettings.vue');
const ApparatusSettingsComponent = () => import('@hq/views/Settings/apparatus/');
const AgenciesSettingsComponent = () => import('@hq/views/Settings/agencies/');
const RefreshSettingsComponent = () => import('@hq/views/Settings/RefreshTables.vue');
const ResponsePlansSettingsComponent = () => import('@hq/views/Settings/ResponsePlans/');
const StationsSettingsComponent = () => import('@hq/views/Settings/Stations/');
const DistributionStudyComponent = () => import('@hq/views/DistributionStudy/DistributionStudy.vue');
/*
 * NOTE
 *
 * Because some users don't have access to the home page, but do have
 * access to one or more apps, we need to detect that and redirect them
 * to the appropriate place. To make that as fast as possible, we detect that
 * in the AppModel and do the redirect there. However, those users should
 * still be able to view static content (like the privacy policy).
 *
 * To handle this, we use the meta property of the routes to indicate which
 * routes should be available to all users. If meta.alwaysAllowAccess is true,
 * we skip the redirect check.
 */
export const route: RouteRecordRaw = {
    name: 'HQ',
    path: '/hq',
    components: {
        sidebar: Sidebar,
        default: EmptyContainer
    },
    meta: { title: 'DHE Home' },
    props: {
        sidebar: route => {
            const isSettingsPageRoute = !!route.matched.find(r => r.name === 'TenantSettings');
            const isDistributionStudy = route.name === 'DistributionStudy';
            const overlayBreakpoint = (isSettingsPageRoute || isDistributionStudy) ? 0 : Number.MAX_SAFE_INTEGER;

            return {
                overlayBreakpoint,
                app: 'Home'
            };
        }
    },
    children: [
        {
            path: '',
            name: 'HqDatabasePicker',
            component: TenantPickerPage,
            props: {
                title: 'Darkhorse HQ',
                app: 'Home',
                allowRedirectToOtherApps: true
            },
            beforeEnter: redirectToValidRouteForUser({ appName: 'Home', allowOtherApps: true })
        },
        {
            name: 'logout',
            path: 'logout',
            redirect: () => {
                daAuthClient.logout();
                return '/hq/login';
            }
        },
        {
            path: 'privacy-policy',
            name: 'PrivacyPolicy',
            component: PrivacyPolicyComponent,
            meta: {
                alwaysAllowAccess: true
            }
        },
        {
            // Any route that doesn't start with /login. Ideally, we'd match everything but _exactly_
            // login, but Vue wraps this regex in some of its own syntax which breaks a naive "everything but
            // login" regex.
            path: ':tenant((?!login\\)[^/]+)',
            name: 'HqMain',
            component: HqAppComponent,
            children: [
                {
                    path: '',
                    name: 'Home',
                    component: HqHomeComponent,
                    redirect: { name: 'HqDashboard' },
                    children: [
                        {
                            path: '',
                            name: 'HqDashboard',
                            component: DashboardComponent,
                        },
                        ...(featureIsEnabled('unitHourUtilization') ? [{
                            path: 'unitHourUtilization',
                            name: 'UnitHourUtilization',
                            component: UnitHourUtilizationReportComponent
                        }] : []),
                        ...(featureIsEnabled('callConcurrency') ? [{
                            path: 'callConcurrency',
                            name: 'CallConcurrency',
                            component: CallConcurrencyReportComponent
                        }] : []),
                        ...(featureIsEnabled('stationResponseReliability') ? [{
                            path: 'stationResponseReliability',
                            name: 'StationResponseReliability',
                            component: StationResponseReliabilityReportComponent
                        }] : []),
                        {
                            path: ':report',
                            name: 'MetricReport',
                            component: MetricReportComponent
                        }
                    ]
                },
                {
                    path: 'settings',
                    name: 'TenantSettings',
                    component: TenantSettingsComponent,
                    beforeEnter: (to, from) => {
                        beforeSettingsHelper.onBeforeEnter(from);
                    },
                    children: [{
                        path: '',
                        name: 'GeneralSettings',
                        component: GeneralSettingsComponent
                    }, {
                        path: 'apparatus',
                        name: 'ApparatusSettings',
                        component: ApparatusSettingsComponent
                    }, {
                        path: 'agencies',
                        name: 'AgenciesSettings',
                        component: AgenciesSettingsComponent
                    }, {
                        path: 'refresh',
                        name: 'RefreshSettings',
                        component: RefreshSettingsComponent
                    },
                    {
                        path: 'response-plans',
                        name: 'ResponsePlanSettings',
                        component: ResponsePlansSettingsComponent
                    },
                    {
                        path: 'stations',
                        name: 'StationsSettings',
                        component: StationsSettingsComponent
                    }]
                },
                ...(featureIsEnabled('homePageReports') ? [{
                    path: 'study',
                    name: 'DistributionStudy',
                    component: DistributionStudyComponent
                }] : [])
            ],
            beforeEnter: (to, from, next) => {
                if (!to.params.tenant) {
                    next({ name: 'DatabasePicker' });
                    return;
                }
                next();
            }
        }
    ]
};
