import { TypeOf, compile, v } from 'suretype';

const riskBoundaryAllMetrics = v.object({
    boundaryId: v.number().integer().required(),
    overall: v.object({
        consequencePctl: v.anyOf([v.number(), v.null()]).required(),
        consequenceScore: v.anyOf([v.number().integer(), v.null()]).required(),
        probabilityScore: v.number().integer().required(),
        riskPctl: v.anyOf([v.number(), v.null()]).required(),
    }).required(),
    consequenceBuckets: v.array(v.object({
        id: v.string().required(),
        overall: v.object({
            consequencePctl: v.anyOf([v.number(), v.null()]).required(),
            consequenceScore: v.anyOf([v.number().integer(), v.null()]).required(),
            exposurePctl: v.anyOf([v.number(), v.null()]).required(),
            exposureScore: v.anyOf([v.number().integer(), v.null()]).required(),
            negativeModifiersPctl: v.anyOf([v.number(), v.null()]).required(),
            negativeModifiersScore: v.anyOf([v.number().integer(), v.null()]).required(),
            positiveModifiersPctl: v.anyOf([v.number(), v.null()]).required(),
            positiveModifiersScore: v.anyOf([v.number().integer(), v.null()]).required(),
        }).required(),
        exposures: v.array(v.object({
            id: v.string().required(),
            value: v.number().required(),
            valuePctl: v.number().required(),
            score: v.number().integer().required(),
        })).required(),
        modifiers: v.object({
            negative: v.array(v.object({
                id: v.string().required(),
                value: v.number().required(),
                valuePctl: v.number().required()
            })).required(),
            positive: v.array(v.object({
                id: v.string().required(),
                value: v.number().required(),
                valuePctl: v.number().required()
            })).required(),
        }).required(),
        flags: v.array(v.object({
            id: v.string().required(),
            value: v.number().required(),
        })).required(),
    })).required(),
    probabilityCategories: v.array(v.object({
        id: v.string().required(),
        numIncidents: v.number().required(),
    })).required(),
});

export const validateRiskBoundaryAllMetrics = compile(riskBoundaryAllMetrics, { ensure: true });
export type RiskBoundaryAllMetrics = TypeOf<typeof riskBoundaryAllMetrics>;
